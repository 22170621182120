/**
    Block stylign for gutenberg block editor

    - GutenbergContent__Block
        - GutenbergContent__Block--File
        - GutenbergContent__Block--Heading
        - GutenbergContent__Block--Image
        - GutenbergContent__Block--List
        - GutenbergContent__Block--Paragraph
        - GutenbergContent__Block--Quote

    - alignfull
    - alignwide
*/

@import 'Styles/includes';

.GutenbergContent {
    $root: &;

    @include wrap;

    @include textstyle(body-regular);

    margin: 24px 0;

    :global {
        .Gutenberg {
            @extend %richtext;
        }
    }

    &__Block {
        $sl-maxwidth: map-get($maxwidths, SL);
        $sl-viewport-spacing: '(100vw - #{$sl-maxwidth}) / 2';
        $m-maxwidth: map-get($maxwidths, M);
        $m-viewport-spacing: '(100vw - #{$m-maxwidth}) / 2';
        $ml-maxwidth: map-get($maxwidths, ML);
        $ml-viewport-spacing: '(100vw - #{$ml-maxwidth}) / 2';
        $l-maxwidth: map-get($maxwidths, L);
        $l-viewport-spacing: '(100vw - #{$l-maxwidth}) / 2';
        $xl-maxwidth: map-get($maxwidths, XL);
        $xl-viewport-spacing: '(100vw - #{$xl-maxwidth}) / 2';
        $xxl-maxwidth: map-get($maxwidths, XXL);
        $xxl-viewport-spacing: '(100vw - #{$xxl-maxwidth}) / 2';

        $longread-ml-maxwidth: 1000px;
        $longread-ml-viewport-spacing: '(100vw - #{$longread-ml-maxwidth}) / 2';
        $wide-longread-ml-viewport-spacing: '(100vw - #{$longread-ml-maxwidth} - 32px) / 2';

        #{$root}--Modules & {
            &:not(#{$root}__Block--AlignWide):not(#{$root}__Block--AlignFull) {
                max-width: map-get($maxwidths, article);
                margin-left: $gutter;
                margin-right: $gutter;

                @include media(SL) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &--AlignWide {
                max-width: map-get($maxwidths, content);
            }

            &--AlignWide,
            &--AlignFull {
                margin-left: $gutter;
                margin-right: $gutter;

                @include media(SL) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        #{$root}--Article & {
            max-width: map-get($maxwidths, article);

            &--AlignWide#{$root}__Block--CoreColumns,
            &--AlignFull#{$root}__Block--CoreColumns {
                padding-left: 8px;

                @include media(M) {
                    padding-left: 16px;
                }

                @include media(L) {
                    padding-left: 24px;
                }
            }
        }

        #{$root}--ArticleLeft & {
            &--AlignWide,
            &--AlignFull {
                width: calc(100vw - #{$gutter});

                @include media(SL) {
                    width: calc(100% + #{$gutter} + (#{$sl-viewport-spacing}));
                    margin-left: calc((#{$sl-viewport-spacing}) * -1);
                }

                @include media(M) {
                    width: calc(100% + 40px + (#{$m-viewport-spacing}));
                    margin-left: calc((#{$m-viewport-spacing}) * -1);
                }

                @include media(ML) {
                    width: calc(100% + 40px + (#{$ml-viewport-spacing}));
                    margin-left: calc((#{$ml-viewport-spacing}) * -1);
                }

                @include media(L) {
                    width: calc(100% + 104px + (#{$l-viewport-spacing}));
                    margin-left: calc((#{$l-viewport-spacing}) * -1);
                }

                @include media(XL) {
                    width: calc(100% + 104px + (#{$xl-viewport-spacing}));
                    margin-left: calc((#{$xl-viewport-spacing}) * -1);
                }

                @include media(XXL) {
                    width: calc(100% + 104px + (#{$xxl-viewport-spacing}));
                    margin-left: calc((#{$xxl-viewport-spacing}) * -1);
                }
            }

            &--AlignFull {
                max-width: none;
            }
        }

        #{$root}--ArticleRight & {
            &--AlignWide,
            &--AlignFull {
                width: calc(100vw - #{$gutter});

                @include media(SL) {
                    width: calc(100% + #{$gutter} + (#{$sl-viewport-spacing}));
                }

                @include media(M) {
                    width: calc(100% + 40px + (#{$m-viewport-spacing}));
                    margin-left: -40px;
                }

                @include media(ML) {
                    width: calc(100% + 40px + (#{$ml-viewport-spacing}));
                }

                @include media(L) {
                    width: calc(100% + 95px + (#{$l-viewport-spacing}));
                    margin-left: -95px;
                }

                @include media(XL) {
                    width: calc(100% + 95px + (#{$xl-viewport-spacing}));
                }

                @include media(XXL) {
                    width: calc(100% + 95px + (#{$xxl-viewport-spacing}));
                }
            }

            &--AlignWide {
                max-width: calc(100% + 80px) !important;

                @include media(L) {
                    max-width: 100% !important;
                }
            }

            &--AlignFull {
                max-width: calc(100% + 80px) !important;

                @include media(L) {
                    max-width: none !important;
                }
            }
        }

        #{$root}--Longread & {
            max-width: map-get($maxwidths, longread);

            &--SagePreamble {
                &:first-child {
                    margin-bottom: 32px;

                    @include media(M) {
                        margin-bottom: 40px;
                    }

                    @include media(L) {
                        margin-bottom: 64px;
                    }
                }
            }

            h2 {
                margin-top: 24px;

                @include media(M) {
                    margin-top: 32px;
                }

                @include media(L) {
                    margin-top: 40px;
                }
            }

            &--AlignWide {
                @include media(SL) {
                    width: calc(100vw - #{$sl-viewport-spacing});
                    margin-left: calc((#{$sl-viewport-spacing} / 2) * -1);
                }

                @include media(M) {
                    width: calc(100vw - #{$m-viewport-spacing});
                    margin-left: calc((#{$m-viewport-spacing} / 2) * -1);
                }

                @include media(ML) {
                    width: auto;
                    margin-left: calc((#{$wide-longread-ml-viewport-spacing}) * -1);
                }

                @include media(L) {
                    margin-left: -90px;
                }

                @include media(XL) {
                    width: 100%;
                    margin-left: calc((100% - map-get($maxwidths, longread)) / -2);
                }
            }

            &--AlignFull {
                max-width: none;

                @include media(SL) {
                    margin-left: calc((#{$sl-viewport-spacing}) * -1);
                }

                @include media(M) {
                    margin-left: calc((#{$m-viewport-spacing}) * -1);
                }

                @include media(ML) {
                    width: calc(100% + (#{$longread-ml-viewport-spacing}));
                    margin-left: calc((#{$longread-ml-viewport-spacing}) * -1);
                }

                @include media(L) {
                    width: calc(100% + 90px + (#{$l-viewport-spacing}));
                    margin-left: calc(((#{$l-viewport-spacing}) + 90px) * -1);
                }

                @include media(XL) {
                    width: calc(100% + 90px + (#{$xl-viewport-spacing}));
                    margin-left: calc(((#{$xl-viewport-spacing}) + 90px) * -1);
                }

                @include media(XXL) {
                    width: calc(100% + 90px + (#{$xxl-viewport-spacing}));
                    margin-left: calc(((#{$xxl-viewport-spacing}) + 90px) * -1);
                }
            }
        }
    }
}
